<template>
  <div class="shopping-container publicList_box">
    <div class="carousel-box" v-if="!fromListing">
      <!--侧边栏分类区域-->
      <div class="left-classification">
        <div class="meun_title_box" v-if="showBoxTalg">
          <Icon type="md-menu" size="26" color="#FD5425" class="ml12" style="position: relative; top: 2px;"/>
          <p class="meun_title">{{ $t('key1000013') }}</p>
        </div>
        <classification :menuList="menuList" @toListBtn="toListBtn"></classification>
      </div>
      <!--右边轮播图、个人信息区域-->
      <div class="right-information">
        <!--活动tab栏-->
        <activityTab v-if="showBoxTalg"></activityTab>
        <div class="flex align-items-end justify-content-between">
          <div class="carousel-map">
            <!--轮播图区域-->
            <div class="rotationChart_box">
              <Carousel v-model="carouselTalg"
                :autoplay="setting.autoplay"
                :autoplay-speed="setting.autoplaySpeed"
                :dots="setting.dots"
                :radius-dot="setting.radiusDot"
                :trigger="setting.trigger"
                :loop="setting.loop"
                @on-click="changeCarousel"
                :arrow="setting.arrow">
                <CarouselItem v-for="(item,index) in carouselList" :key="index">
                  <img class="banner_img" :src="item.imagePath"/>
                </CarouselItem>
              </Carousel>
            </div>
            <!-- 默认的主分类图-->
            <div class="main_classification_box">
              <div class="main_classification_item" v-for="(item,index) in vajraDistrictList" :key="index" @click="advertisingLinkAddress(item)">
                <img class="banner_img" :src="item.imagePath"/>
                <h3 class="title" :title="item.name">{{ item.name }}</h3>
              </div>
            </div>
          </div>
          <!--个人信息栏-->
          <div class="personal_info_box">
            <div class="content">
              <div class="head-information" v-if="$store.state.erpConfig">
                <img class="user-icon" :src="headPortraitUrl"/>
                <div class="basic-info">
                  <p class="tag ellipsis_1" :title="distributorName">{{ distributorName }}</p>
                  <p>{{ $t('key1000071') }}<span v-if="$store.state.erpConfig">{{ $store.state.erpConfig.merchant.merchantId }}</span></p>
                </div>
              </div>
              <div class="head-info-noLogin" v-else>
                <img class="user-icon" :src="headPortraitUrl"/>
                <p class="text">{{ $t('key1000081') }}</p>
                <div class="head-info-btn">
                  <Button class="btn" @click="register">{{ $t('key1000074') }}</Button>
                  <Button class="btn" type="primary" @click="login">{{ $t('key1005239') }}</Button>
                </div>
              </div>
              <div class="order" v-if="$store.state.erpConfig">
                <div class="order_number_item">
                  <p class="number">{{ orderInfo.pendingQuantity }}</p>
                  <p class="text" :title="$t('key1002062')">{{ $t('key1002062') }}</p>
                </div>
                <div class="order_number_item">
                  <p class="number">{{ orderInfo.shippedQuantity }}</p>
                  <p class="text" :title="$t('key1001179')">{{ $t('key1001179') }}</p>
                </div>
                <div class="order_number_item">
                  <p class="number">{{ orderInfo.isShippedQuantity }}</p>
                  <p class="text" :title="$t('key1001166')">{{ $t('key1001166') }}</p>
                </div>
              </div>
              <img class="info_banner_img" v-if="infoAdvertisingImg" :src="infoAdvertisingImg" alt="" @click="advertisingLinkAddress(jumpTargetObj)">
              <Card :bordered="false" dis-hover class="help_center_box" v-if="helpCenterList.length>0">
                <template #title>
                  <div class="title_box">
                    <h2 class="title">{{ $t('key1000036') }}</h2>
                    <span class="text" @click="helpCenterUrl(null, 'personalInfo', '_blank')">{{ $t('key1000019') }}</span>
                  </div>
                </template>
                <div class="help_center">
                  <span class="help_center_item" v-for="(item,index) in helpCenterList" @click="helpCenterUrl(item.id, null, '_blank')"
                    :key="index" :title="item.title" target="_blank"> {{ item.title }}</span>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--每日上新、热销商品-->
    <div class="flex justify-content-between card_box_styles">
      <Card :bordered="false" dis-hover class="card_box_item" v-for="(item, index) in dailyUpdateList" :key="index">
        <template #title>
          <div class="title_box">
            <h2 class="title">{{ item.title }}</h2>
            <div class="flex align-items hoverStyles">
              <span class="text" @click="moreBtn(item)">{{ $t('key1000090') }}</span>
              <Icon size="16" class="forward_icon_styles" type="ios-arrow-forward"/>
            </div>
          </div>
        </template>
        <div class="card_content_box">
          <div class="card_content_item" v-for="(ele,idx) in item.list" :key="idx">
            <div @click="gotoPath(`/shopDetails/${ele.ymsProductId}`)">
              <div class="img_box_styles">
                <image-box :src="getThumbnailImage(ele.primaryImage)" :animation="true"></image-box>
              </div>
              <p class="title" :title="ele.name">{{ ele.name }}</p>
              <p class="price" v-if="userInfo">{{ currencyState + currencySymbol(currencyState) + ele.maxSuggestPrice }}</p>
              <p class="price" v-else>{{ $t('key1005240') }}</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <!--商品推荐列表-->
    <div class="recommend_box">
      <div class="recommend_box_item" v-for="(item, index) in commodityList" :key="index">
        <div class="recommend_title">
          <h2 class="title">{{ item.title }}</h2>
          <div class="flex align-items hoverStyles">
            <span class="text" @click="moreBtn(item)">{{ $t('key1000090') }}</span>
            <Icon size="16" class="forward_icon_styles" type="ios-arrow-forward"/>
          </div>
        </div>
        <div class="recommend_content">
          <div class="recommend_content_item" v-for="(ele,idx) in item.list" :key="idx">
            <div @click="gotoPath(`/shopDetails/${ele.ymsProductId}`)">
              <div class="img_box_styles">
                <image-box :src="getThumbnailImage(ele.primaryImage)" :animation="true"></image-box>
              </div>
              <p class="title" :title="ele.name">{{ ele.name }}</p>
              <p class="price" v-if="userInfo">{{ currencyState + currencySymbol(currencyState) + ele.maxSuggestPrice }}</p>
              <p class="price" v-else>{{ $t('key1005240') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';


import classification from '@/components/layout/shopping/common/classification';
import imageBox from '@/components/common/imageBox';
import api from '@/api/';
import commonMixin from '@/components/mixin/common_mixin';
import activityTab from "@/components/common/activityTab";
import {getAdidToken, setAdidToken} from "@/utils/cookie";
import {advertisingLinkAddress, handerDataSorting, pageJump, getHrefPath, amountHandel, getQueryString} from "@/utils/common";

export default {
  name: 'index',
  data() {
    return {
      carouselTalg: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 4000,
        dots: 'outside',
        radiusDot: true,
        trigger: 'click',
        arrow: 'always',
        loop: true
      },
      distributorName: '',
      headPortraitUrl: require('@/assets/images/headPortrait.png'),
      orderInfo: {
        isShippedQuantity: 0,
        shippedQuantity: 0,
        pendingQuantity: 0
      },
      helpCenterList: [],
      dailyUpdateList: [],
      commodityList: [],
      jumpTargetObj: null,
      adid: null,
    };
  },
  mixins: [commonMixin],
  computed: {
    fromListing() {
      return this.$store.state.fromListing
    },
    menuList() {
      return this.$store.state.classificationData;
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 是否隐藏频道tab栏
    showBoxTalg() {
      let tlag = this.$store.state.navBarFixed;
      this.$store.commit('showHover', tlag);
      return !tlag;
    },
    // 金刚区的数据
    vajraDistrictList() {
      let advertisingList = this.$store.state.mallAdvertising;
      let list = [];
      if (advertisingList && advertisingList.length > 0) {
        advertisingList.map((item) => {
          if (item.orientationType === 4) {
            list = item.ymsAdvertisementDetailBos;
          }
        });
      }
      return list;
    },
    // 首页-个人信息下方广告位
    infoAdvertisingImg() {
      let advertisingList = this.$store.state.mallAdvertising;
      let imagePath = '';
      if (advertisingList && advertisingList.length > 0) {
        advertisingList.map((item) => {
          if (item.orientationType === 2) {
            if (item.status) {
              imagePath = item.ymsAdvertisementDetailBos[0].imagePath;
              this.jumpTargetObj = item.ymsAdvertisementDetailBos[0];
            }
          }
        });
      }
      return imagePath;
    },
    // 轮播图数据
    carouselList() {
      let advertisingList = this.$store.state.mallAdvertising;
      let list = [];
      if (advertisingList && advertisingList.length > 0) {
        advertisingList.map((item) => {
          if (item.orientationType === 1) {
            list = item.ymsAdvertisementDetailBos.filter((ele) => {
              return ele.imagePath
            });
          }
        });
      }
      return list;
    }
  },
  created() {
    this.incidentReporting();
    this.getRecommendedArticles();
    this.getListData();
  },
  mounted() {
    if (!localStorage.getItem('noFirstEntry')) {
      this.$parent.$refs.gettingStarted.open();
    }
    if (this.$store.state.erpConfig) {
      this.getStatistics();
      this.getName();
    }

  },
  methods: {
    advertisingLinkAddress,
    // 获取商户订单信息
    getStatistics() {
      let v = this;
      v.axios.get(api.get_ymsDistributorPackageInfo_queryStatusStatistics).then((response) => {
        if (response.data.code === 0) {
          v.orderInfo = response.data.datas;
        }
      });
    },
    // 跳转到商城详情页
    gotoPath(path) {
      if (this.fromListing) {
        this.$router.push({
          path: path
        });
      } else {
        pageJump('/index.html#' + path, '/yms-shopping-service', '_blank')
      }
    },
    // 获取商户信息
    getName() {
      let v = this;
      v.loading = true;
      v.axios.get(api.get_ymsDistributorMerchant_getYmsDistributorMerchantDetail).then(response => {
        v.loading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.distributorName = v.handleMultilingualFields(data.queryDistributorMerchantGradeResult.name)
          }
        }
      });
    },
    // 免费注册
    register() {
      pageJump('/login.html#/register', '/yms-shopping-service', '_blank');
    },
    // 登录
    login() {
      let url = '/login.html#/login';
      let service = '/yms-shopping-service';
      let type = '_self'; // 在当前窗口打开, _blank 在新窗口打开
      let links = '?u=' + window.location.href;
      pageJump(url, service, type, links);
    },
    // 获取列表数据
    getListData() {
      let v = this;
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      let url = '';
      if (v.userInfo.merchantId) {
        url = api.get_ymsProductInfo_shopping_queryForShowCase + '?distributorMerchantId=' + v.userInfo.merchantId;
      } else {
        url = api.get_ymsProductInfo_shopping_queryForShowCase;
      }
      v.axios.get(url, config).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let typeList = [
            {type: 6, title: alias2f085dd487c1465383484787b29fef2c.t('key1005241')},
            {type: 1, title: alias2f085dd487c1465383484787b29fef2c.t('key1005242')},
            {type: 2, title: alias2f085dd487c1465383484787b29fef2c.t('key1005243')},
            {type: 3, title: alias2f085dd487c1465383484787b29fef2c.t('key1005244')},
            {type: 4, title: alias2f085dd487c1465383484787b29fef2c.t('key1005245')},
            {type: 5, title: alias2f085dd487c1465383484787b29fef2c.t('key1005246')}
          ];
          // 睿栀的需要屏蔽 潮流女装、时尚男装、精品童装模块
          if (!v.$store.state._isMultiSpecificationAttributeMerchant) {
            typeList = typeList.filter((item) => {
              if (![1, 2, 3].includes(item.type)) {
                return item;
              }
            });
            data = data.filter((item) => {
              if (![1, 2, 3].includes(item.type)) {
                return item;
              }
            });
          }
          if (data && data.length > 0) {
            data.map((item) => {
              typeList.map((ele) => {
                if (item.type === ele.type) {
                  item.title = ele.title;
                  item.list = item.queryYmsProductInfoForShoppingResultBos || [];
                  if (item.list.length > 0) {
                    item.list.map((talg) => {
                      talg.maxSuggestPrice = amountHandel(talg.maxSuggestPrice);
                    })
                  }
                }
              })
            });
            let arr1 = data.filter((item) => {
              return item.type === 6 || item.type === 4;
            });
            arr1.map((item) => {
              item.list = item.list.slice(0, 3) || [];
            })
            v.dailyUpdateList = arr1;
            v.commodityList = data.filter((item) => {
              if (![4, 6].includes(item.type)) {
                return item;
              }
            });
          }
        }
      });
    },
    // 点击更多按钮跳转到商品列表
    moreBtn(item) {
      if (item.ymsProductCategoryId) {
        if (this.fromListing) {
          window.location.href = getHrefPath(`/index.html#/shopList?ymsProductCategoryId=${item.ymsProductCategoryId}&commodityType=${item.type}`, '/yms-shopping-service');
        } else {
          pageJump(`/index.html#/shopList?ymsProductCategoryId=${item.ymsProductCategoryId}&commodityType=${item.type}`,
            '/yms-shopping-service', '_blank');
        }
      } else {
        if (this.fromListing) {
          window.location.href = getHrefPath(`/index.html#/shopList?activityTitle=${item.title}&commodityType=${item.type}`,
            '/yms-shopping-service',);
        } else {
          pageJump(`/index.html#/shopList?activityTitle=${item.title}&commodityType=${item.type}`,
            '/yms-shopping-service', '_blank');
        }

      }
    },
    // 跳转到列表页
    toListBtn(data) {
      this.$router.push({
        path: '/shopList',
        query: {
          ymsProductCategoryId: data.ymsProductCategoryId
        }
      });
    },
    // 点击banner进入活动列表
    changeCarousel(index) {
      let v = this;
      v.carouselList.map((item, idx) => {
        if (index === idx) {
          advertisingLinkAddress(item);
        }
      });
    },
    // 查询帮助中心推荐文章
    getRecommendedArticles() {
      let v = this;
      let query = {
        firstShowStatus: 1
      };
      v.axios.post(api.post_ymsHelpDocs_shopping_listDocs, query).then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data && data.length > 0) {
            data.map((item) => {
              let helpDocInternationalList = item.helpDocInternationalList || [];
              if (helpDocInternationalList.length > 0) {
                helpDocInternationalList.map((ele) => {
                  if (ele.language === v.languagesVal) {
                    item.title = ele.title;
                  }
                })
              }
            });
            v.helpCenterList = data.sort(handerDataSorting('showQuantity', 'desc'));
          }
        }
      });
    },
    // 跳转到帮助中心
    helpCenterUrl(id, type, target) {
      let url = '';
      if (id) {
        url = `/index.html#/help?id=${id}`;
        let service = '/yms-shopping-service';
        let link = getHrefPath(url, service);
        this.axios.get(api.get_ymsHelpDocs_shopping_recordShowQuantity + `?id=${id}`).then(response => {
          if (response.data.code === 0) {
            window.open(link, target);
          }
        });
      }
      if (type) {
        url = `/index.html#/help?type=${type}`;
        let service = '/yms-shopping-service';
        let link = getHrefPath(url, service);
        window.open(link, target);
      }
    },
    // 商城首页埋点及事件上报(TMS跳转到yms商城页面携带有adid参数时才触发)
    incidentReporting() {
      let v = this;
      v.adid = null;
      let urlAdid = getQueryString('adid');
      let adidToken = getAdidToken('adidToken');
      let adid = urlAdid || adidToken;
      if (urlAdid || adidToken) {
        let query = {
          adid: adid
        }
        v.axios.post(api.post_ymsDistributorMerchant_tokerLinkEventReport, query).then(response => {
          if (response.data.code === 0) {
            v.adid = adid;
            if (urlAdid) {
              setAdidToken(adid)
            }
          }
        });
      }
    }
  },
  components: {
    classification,
    imageBox,
    activityTab
  }
};
</script>

<style>
.ivu-carousel-track, .ivu-carousel-list {
  height: 100%;
}

.ivu-carousel-list .ivu-carousel-item {
  overflow: hidden;
}

.ivu-carousel-dots-outside {
  position: absolute;
  bottom: 6px;
}

.ivu-carousel-track + .ivu-carousel-track {
  margin-left: -1px !important;
}
</style>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.carousel-box {
  display: flex;
  justify-content: space-between;

  .left-classification {
    width: 280px;
    background-color: #EFF0F5;
    z-index: 100;

    .meun_title_box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #fff;

      .meun_title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: bold;
        padding-left: 12px;
        height: 40px;
        line-height: 40px;
        color: @subject_color;
      }
    }
  }

  .right-information {
    min-height: 460px;
    width: 912px;
    background-color: #EFF0F5;

    .carousel-map {
      width: 644px;
      box-sizing: border-box;

      .rotationChart_box {
        width: 644px;
        height: 324px;
        cursor: pointer;
        margin-bottom: 8px;
        background: #FFFFFF;
        border-radius: 4px;
        overflow: hidden;

        /deep/ .ivu-carousel {
          width: 100%;
          height: 100%;

          .ivu-carousel-dots-inside {
            bottom: 18px;
          }

          .radius {
            width: 8px;
            height: 8px;
          }

          .ivu-carousel-active {
            .radius {
              background-color: #FD5425;
            }
          }

          .ivu-carousel-arrow {
            width: 24px;
            height: 32px;
            background: #000000;
            opacity: 0.4;
            display: flex;
            justify-content: center;
            align-items: center;

            .ivu-icon {
              font-size: 22px;
              color: #fff;
            }
          }

          .ivu-carousel-arrow.right {
            right: 0;
            border-radius: 4px 0px 0px 4px;
          }

          .ivu-carousel-arrow.left {
            border-radius: 0px 4px 4px 0px;
            left: 0;
          }
        }

        .banner_img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      .main_classification_box {
        width: 100%;
        height: 128px;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 17px 42px 20px 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .main_classification_item {
          width: 60px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;

          .banner_img {
            display: block;
            width: 60px;
            height: auto;
            max-height: 60px;
          }

          .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            width: 100%;
            margin-top: 12px;
            font-size: 14px;
            font-family: 'PingFangSC-Regular';
            color: #000;
          }
        }
      }
    }

    .personal_info_box {
      width: 260px;
      height: 460px;
      padding: 14px;
      background-color: #ffffff;

      .head-information {
        display: flex;
        align-items: center;

        .user-icon {
          display: block;
          font-size: 48px;
        }

        .basic-info {
          margin-left: 10px;

          .tag {
            background-color: #555555;
            color: #ffffff;
            display: inline-block;
            border-radius: 5px;
            padding: 3px 3px;
            font-size: 12px;
            margin-bottom: 8px;
            max-width: 150px;
          }
        }
      }

      .head-info-noLogin {
        margin-top: 6px;
        text-align: center;

        .user-icon {
          font-size: 40px;
        }

        .text {
          color: #333333cc;
          margin-top: 5px;
        }

        .head-info-btn {
          margin: 15px 0 15px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn {
            margin: 0 10px;
          }
        }
      }

      .order {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        margin: 20px 0 25px 0;

        .order_number_item {
          flex: 1;
          text-align: center;
        }

        .number {
          font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 20px;
          color: #000000;
          text-align: center;
        }

        .text {
          color: #00000099;
          font-size: 14px;
          font-family: 'Arial Normal', 'Arial', sans-serif;
          max-width: 77px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }

      .sub-banner {
        width: 228px;
        height: 70px;
        margin: 0 auto;
        background-color: #f8f1f3;
      }

      .banner-help {
        .title {
          margin: 28px 0 20px 0;

          .help {
            font-weight: bold;
            color: #000;
          }

          .more {
            cursor: pointer;
          }

          .more:hover {
            color: @subject_color;
          }
        }

        .item {
          margin-bottom: 10px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item:hover {
          color: @subject_color;
        }
      }

      .info_banner_img {
        width: 228px;
        height: 70px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 15px;
      }

      .help_center_box {
        /deep/ .ivu-card-head {
          padding: 10px 0;

          .title_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              font-size: 14px;
              font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
              font-weight: bold;
              color: #000000;
            }

            .text {
              font-size: 12px;
              font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
              font-weight: 400;
              color: #000000;
              cursor: pointer;
            }
          }
        }

        /deep/ .ivu-card-body {
          padding: 8px 0 0 0;

          .help_center {
            width: 100%;

            .help_center_item {
              width: 100%;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 5px;
              font-size: 13px;
              font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
              color: #666;

              &:hover {
                text-decoration: underline;
                cursor: pointer;
                color: @subject_color;
              }
            }
          }
        }
      }
    }
  }
}

.card_box_styles {
  margin-top: 16px;

  .card_box_item {
    width: 592px;

    /deep/ .ivu-card-head {
      padding: 12px 10px 12px 15px;
      border-bottom: none;
    }

    .title_box {
      display: flex;
      justify-content: space-between;

      .title {
        font-family: 'PingFangSC-Semibold';
        font-weight: bold;
        font-size: 18px;
        color: #000;
      }

      .text {
        cursor: pointer;
        color: #666;
        font-size: 14px;
        font-family: 'PingFangSC-Regular';
        cursor: pointer;
      }

      .hoverStyles:hover {
        .text {
          color: @subject_color;
          text-decoration: underline;
        }

        .forward_icon_styles {
          color: @subject_color;
        }
      }
    }

    /deep/ .ivu-card-body {
      padding: 10px 15px;
    }

    .card_content_box {
      display: flex;
      justify-content: space-between;

      .card_content_item {
        width: 176px;
        height: 240px;
        cursor: pointer;

        .img_box_styles {
          width: 176px;
          height: 176px;
          overflow: hidden;
          display: inline-block;
        }

        .title {
          padding: 0 9px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
          font-size: 14px;
          font-family: 'PingFangSC-Regular';
          margin: 8px 0;
        }

        .price {
          padding: 0 9px;
          font-family: 'PingFangSC-Semibold';
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.recommend_box {
  width: 100%;
  margin-top: 24px;

  .recommend_box_item {
    margin-bottom: 24px;

    .recommend_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      .title {
        font-family: 'PingFangSC-Semibold';
        font-weight: bold;
        font-size: 18px;
        color: #000;
      }

      .text {
        cursor: pointer;
        color: #666;
        font-size: 14px;
        font-family: 'PingFangSC-Regular';
      }

      .hoverStyles:hover {
        .text {
          color: @subject_color;
          text-decoration: underline;
        }

        .forward_icon_styles {
          color: @subject_color;
        }
      }
    }

    .recommend_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .recommend_content_item {
        width: 228px;
        height: 298px;
        background-color: #fff;
        cursor: pointer;
        margin: 0 15px 15px 0;

        .img_box_styles {
          width: 228px;
          height: 228px;
          overflow: hidden;
          display: inline-block;
        }

        .title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
          font-size: 14px;
          font-family: 'PingFangSC-Regular';
          padding: 0 8px;
          margin: 6px 0 8px 0;
        }

        .price {
          font-family: 'PingFangSC-Semibold';
          padding: 0 8px;
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }

        &:hover {
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1500);
        }
      }

      .recommend_content_item:nth-child(5n+5) {
        margin-right: 0;
      }
    }
  }
}
</style>
